function loadImages() {
    var defer = jQuery.Deferred();
    var images = $("[data-src]");
    var done = [];

    if(images.length){
        images.each(function(n){
            var img  = $(this);
            var src = img.attr('data-src');
            var device = img.attr('data-device');

            src = isMobile() ? src.replace('/device/', '/mobile/') : src.replace('/device/', '/desktop/');

            if(device && ((isMobile() && device == 'desktop') || (!isMobile() && device == 'mobile'))){
                done.push(img[0]);
                img.remove();
            }else {
                switch(img[0].tagName){
                    case 'VIDEO':
                        img.attr('poster', src);
                        done.push(img[0]);
                        break;
                    case 'IMG':
                        img.attr('src', src)
                        img.on('load', function(){
                            done.push(img[0]);
                        });
                        img.on('error', function(){
                            done.push(img[0]);
                        });
                        break;
                    default:
                        img.css('background-image', 'url(' + src + ')')
                        done.push(img[0]);
                        break;
                }
            }
        });

        var wait = setInterval(function(){
            var loaded = images.length <= done.length;
            if(loaded){
                clearInterval(wait);
                defer.resolve();
            }
        }, 100);
    } else {
        defer.resolve();
    }
    return defer.promise()
};
