// Midia Tags
function postMidiaTag(el) {
    var defer = jQuery.Deferred();
    var frame = document.getElementById("midiaTags");

    if (frame){
        $(frame).on('load', function(){
            defer.resolve();
        });
        frame.src = $(el).attr('data-tag-midia') + window.location.search;
    } else {

        $('body').append('<iframe id="midiaTags" src="" width="1" height="1" frameborder="0" style="display:none"></iframe>');

        frame = document.getElementById("midiaTags");
        $(frame).on('load', function(){
            defer.resolve();
        })
        frame.src = $(el).attr('data-tag-midia') + window.location.search;
    }

    return defer.promise()
};

function midiaLink(ev) {

    var el = ev.currentTarget;

    var is_link = el.tagName == "A";
    var is_blank = el.target ? (el.target == "_blank") : false;

    if(is_link && !is_blank){
        postMidiaTag(el).then(function(){
            var href = el.getAttribute('data-href');
            if(href){
                setTimeout(function(){
                    window.location = href;
                }, 300)
            }
        });
    } else {
        postMidiaTag(el);
    }
};
