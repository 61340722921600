const browsers = browserId()
let videoPlayer

// Breakpoints Value
const brkp = {
    'mobile' : 980,
    'tablet' : 1024
}

// Check Mobile Version
function isMobile () { return window.innerWidth <= brkp.mobile }
function isTablet () { return window.innerWidth <= brkp.tablet }

window.onresize = () => {
    loadImages()
}

$(document).ready(function(){
    anchor()

    loadImages().then(function(){
        bindBrands();
    })
})
