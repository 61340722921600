function browserId () {
    var returnObj = {
        'isChromium' : !!window.chrome,
        'isWebkit' : 'WebkitAppearance' in document.documentElement.style,
        'isChrome' : !!window.chrome,
        'isFF' : !!window.sidebar,
        'isOpera' : !!window.opera || /opera|opr/i.test(navigator.userAgent),
        'isOperaMini' : Object.prototype.toString.call(window.operamini) === '[object OperaMini]',
        'isIE' : !!window.ActiveXObject || ('-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style),
        'ieVersion' : (/msie\s(\d+)/i.exec(navigator.userAgent)) ? /msie\s(\d+)/i.exec(navigator.userAgent)[1] : false,
        'isEdge' : /Edge\/\d+/i.test(navigator.userAgent),
        'isSafari' : /constructor/i.test(window.HTMLElement),
        'isAndroid' : (!!window.chrome && !window.chrome.webstore && !(!!window.opera || /opera|opr/i.test(navigator.userAgent))),
        'isIOS' : /iPhone|iPad/i.test(navigator.userAgent),
        'isIPhone' : /iPhone/i.test(navigator.userAgent),
        'isIPad' : /iPad/i.test(navigator.userAgent)
    };

    var classArray = [];

    for (var k in returnObj) {
        switch (k) {
            case 'isChromium':
                if (returnObj[k]) {
                    classArray.push('chromium');
                }
                break;
            case 'isWebkit':
                if (returnObj[k]) {
                    classArray.push('webkit');
                }
                break;
            case 'isChrome':
                if (returnObj[k]) {
                    classArray.push('chrome');
                }
                break;
            case 'isFF':
                if (returnObj[k]) {
                    classArray.push('firefox');
                }
                break;
            case 'isOpera':
                if (returnObj[k]) {
                    classArray.push('opera');
                }
                break;
            case 'isOperaMini':
                if (returnObj[k]) {
                    classArray.push('opera-mini');
                }
                break;
            case 'isIE':
                if (returnObj[k] && !returnObj.isEdge) {
                    classArray.push('ie');
                }
                break;
            case 'ieVersion':
                if (returnObj[k]) {
                    classArray.push('ie' + returnObj[k]);
                } else if (returnObj.isIE && !returnObj.isEdge) {
                    classArray.push('ie11');
                }
                break;
            case 'isEdge':
                if (returnObj[k]) {
                    classArray.push('edge');
                }
                break;
            case 'isSafari':
                if (returnObj[k]) {
                    classArray.push('safari');
                }
                break;
            case 'isAndroid':
                if (returnObj[k] && !returnObj.isEdge) {
                    classArray.push('android');
                }
                break;
            case 'isIOS':
                if (returnObj[k]) {
                    classArray.push('ios');
                }
                break;
            case 'isIPhone':
                if (returnObj[k]) {
                    classArray.push('iphone');
                }
                break;
            case 'isIPad':
                if (returnObj[k]) {
                    classArray.push('ipad');
                }
                break;
        }
    }

    document.documentElement.className =   ( classArray.join(' ') + ' js browserId ' + document.documentElement.className.replace(/\b(no[-|_]?)?js\b/g,"")  ).replace(/^ /, "").replace(/ +/g," ");

    return returnObj;
}
