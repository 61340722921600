function bindBrands() {
    const count = $('.brands__list').data('initialCount')

    $('.brands__list--item').each(function (index) {
        if (index < count) {
            $(this).addClass('open')
        }
    })

    $('.brands__open-button').on('click', function() {
        $('.brands__list--item').addClass('open')

        $(this).remove()
    })
}