var project = 'techgirls',
    prop2 = "campaign",
    prop3 = "seda",
    country = 'br',
    language = 'br',
    init = "main";

var om = {
    project: null,
    country: null,
    language: null,
    omniture: null,

    omniClick: function(){
        var me = this;

        var checkLink = function(ev, el){
            var is_link = el.tagName == "A";
            var is_blank = el.target ? (el.target == "_blank") : false;
            parseData(el);
            if(is_link && !is_blank){
                ev.preventDefault();
                me.timerTrack('omniture', el).then(function(){
                    window.location = el.href
                });
            } else {
                me.timerTrack('omniture', el);
            }
        };

        var parseData = function(el) {
            var data = $(el).data('omniture');

            if (typeof data === "string") {
                var retData = {
                    "title": country + ':' + prop2 + ':' + prop3 + ':' + project + ':' + init + ':' + data
                };

                $(el).data('omniture', retData);
            }
        }

        $(document).on('click', '[data-omniture]', function (e) {
            checkLink(e, this);
        });

        $('nav [data-omniture]').click(function(e) {
            checkLink(e, this);
        })

        $(document).on('click', '[data-omni-click-code]', function (e) {
            var data = $(this).data('omniClickCode');

            sendClickCode(data.type, data.code);
        });

        $(document).on('click', '[data-omniture-pageview]', function (e) {
            me.trackTags('tagPageview', [me.removeSpecial($(this).data('omniture-pageview').title)]);
        });
    },
    initialize: function (omnitureVar) {
        this.project = $('[data-omni-project]').eq(0).attr("data-omni-project") || project;
        this.country = $('[data-omni-country]').eq(0).attr("data-omni-country") || country;
        this.language = $('[data-omni-lang]').eq(0).attr("data-omni-lang") || language;

        prop2 = $('[data-omni-prop2]').eq(0).attr("data-omni-prop2") || prop2;
        prop3 = $('[data-omni-prop3]').eq(0).attr("data-omni-prop3") || prop3;
        init = $('[data-omni-init]').eq(0).attr("data-omni-init") || init;

        var me = this;

        me.omniClick();

        me.trackTags('tagPageview', [init]);
        me.scrollTrack();

        me.available(omnitureVar);
    },
    available: function(omnitureVar){
        omnitureVar = omnitureVar || 's';

        var me = this;
        var omniture = window[omnitureVar];

        if (typeof omniture == "undefined" || !omniture) {
            window.setTimeout(function(){
                me.available(omnitureVar);
            }, 1000);

            return;
        }

        this.omniture = omniture;
        this.omniture.linkInternalFilters = "javascript:,samsung.com,#,/";
        this.omniture.currencyCode = 'BRL';

        while(this.trackStack.length > 0){
            this.trackTags.apply(this, this.trackStack.shift());
        }
    },
    replacers: {
        'e': /[éèëê]/gim,
        'a': /[áàãäâ]/gim,
        'i': /[íìïî]/gim,
        'o': /[óòõöô]/gim,
        'c': /[ç]/gim,
        'u': /[úùüû]/gim,
        ' ': /\s+/g,
        '': /(^\s+|\s+$)|([^a-zA-Z0-9\:\-\_\|\/\s])/g,
        '-': /(\s)|(\-\s)|(\s\-)/g
    },
    removeSpecial: function (string) {
        for (var key in this.replacers) {
            if (string && string.replace) {
                string = string.replace(this.replacers[key], key);
            }
        }
        return string/*.toLowerCase()*/;
    },
    trackStack: [],
    trackTags: function(tagType, options){
        var me = this;

        if(me.omniture){
            me[tagType] && me[tagType].apply(me, options);
        }else{
            me.trackStack.push(arguments);
        }
    },
    tagClick: function (el, tagName, tagType) {

        if (!tagType) {
            tagType = 'o';
        }
        this.omniture.linkTrackVars = 'eVar33,events';
        this.omniture.linkTrackEvents = 'event45';
        this.omniture.eVar33 = this.country + ':' + this.project + ':' + tagName;
        this.omniture.events = 'event45';

        try {
            this.omniture.tl(el, tagType, this.country + ':' + this.project + ':' + tagName);
        } catch (e) {
            // console.log(e);
        }
    },
    tagPageview: function (tag, complement) {
        this.clearProps();

        this.omniture.pageName = this.country + ':' + prop2 + ':' + prop3 + ':' + this.project + ':' + tag + (complement || "");
        this.omniture.channel = this.country + ':campaign';
        this.omniture.prop1 = this.country;
        this.omniture.prop2 = this.omniture.prop1 + ":" + prop2;
        this.omniture.prop3 = this.omniture.prop2 + ":" + prop3;
        this.omniture.prop4 = this.omniture.prop3 + ":" + this.project
        this.omniture.prop5 = this.omniture.prop4 + ":" + tag;
        this.omniture.hier1 = this.country+">"+prop2+">"+prop3+">"+project+">"+tag;
        try {
            var s_code = this.omniture.t();
            if (s_code)document.write(s_code);
        } catch (e) {
            // console.log(e);
        }
    },
    timerTrack: function (data, scope) {
        var me = this;

        var defer = jQuery.Deferred();

        setTimeout($.proxy(function(){
            var omnitags = $(scope).data(data);
            me.trackTags('tagClick', [scope, me.removeSpecial(omnitags.title)]);
            defer.resolve();
        }, scope), 100);

        return defer.promise()

    },
    scrollTrack: function(){
        var me = this;

        var omni_scroll = $("[data-omni-scroll]");
        $(window).on('scroll', function(){
            omni_scroll.each(function(){
                var y = ($(this).offset().top - (window.innerHeight*0.2));
                var scrollOmni = window.scrollY >= y;

                var _that = $(this).attr('data-omni-scroll');
                if(scrollOmni){
                    me.trackTags('tagPageview', [me.removeSpecial(_that)]);
                    $(this).removeAttr('data-omni-scroll');
                    omni_scroll = $("[data-omni-scroll]");
                }
            })
        });
    },
    slickTrack: function(slick){
        var me = this;

        var last_omni,
        target = $(slick);

        target.on('afterChange', function(e, s, n, c){
            var current = target.find(".slick-current");
            var _omni = current.attr('data-omni-slick');
            if(_omni && _omni !== last_omni){
                last_omni = _omni;
                me.timerTrack('omniSlick', current[0]);
            }
        });
    },
    clearProps: function () {
        this.omniture.pageName = '';
        this.omniture.channel = '';
        this.omniture.prop1 = '';
        this.omniture.prop2 = '';
        this.omniture.prop3 = '';
        this.omniture.prop4 = '';
        this.omniture.prop5 = '';
        this.omniture.hier1 = '';
        this.omniture.eVar33 = '';
        this.omniture.events = '';
        this.omniture.eVar1 = '';
        this.omniture.eVar2 = '';
        this.omniture.eVar3 = '';
        this.omniture.eVar4 = '';
        this.omniture.eVar5 = '';
        this.omniture.eVar6 = '';
        this.omniture.eVar7 = '';
        this.omniture.linkTrackVars = "None";
        this.omniture.linkTrackEvents = "None";
    }
}
