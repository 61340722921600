function anchor() {
    $('a[href^=\\#]').not('[disabled]').on('click', function(e) {
        e.preventDefault()

        const el = $(this).attr('href')
        const goTo = (el !== "#") ? $(el).offset().top : 0;

        $("html, body").animate({ 'scrollTop': goTo }, 700)
    })
}
